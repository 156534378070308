.semihead {
  color: #7692ff;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.1em;
}
.header {
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
}

.semitext {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff66;
}
.homepage-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homepage-container {
  margin-top: -150px;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.decoration-left{
  position: absolute;
  bottom: 0;
  left: 0;
}
.decoration-right{
  position: absolute;
  bottom: 0;
  right: 0;
}
.team-container {
  display: flex;
  justify-content: center;
  height: 120vh;
}
.homepage-br {
  width: 100vw;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 60px;
}
.homepage-project {
  margin-top: 160px;
  display: flex;
  justify-content: space-around;
  width: 100vw;
}
.homepage-glassbox {
  width: 400px;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
}
.homepage-project-detail-div {
  display: flex;
  flex-direction: column;
  width: 40vw;
}
.detail {
  color: rgba(255, 255, 255, 0.4);
  font-size: 16px;
}
