.team-title {
    font-weight: 700;
    font-size: 40px;
    color: #ffffff;
    line-height: 54px;
}

.team-subtitle {
    padding-bottom: 20px;
}

.team-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 80vw;
    margin-top: 60px;
    gap: 70px 10vw;
}

.team-image {
    width: 240px;
    height: 240px;
    border-radius: 16px;
}

.team-card{
    padding: 10px 20px;
}

.team-name {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 0.1em;
    margin-top: 16px;
}

.team-id {
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 0.1em;
    margin-top: 4px;
}

.team-description {
    font-size: 10px;
    font-weight: 400;
    color: #7692FF;
    letter-spacing: 0.1em;
    margin-top: 4px;
}
