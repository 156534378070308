.container {
  padding-left: 207px;
  padding-right: 207px;
  display: flex;
  justify-content: space-between;
}
.page {
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  position: absolute;
}
h1 {
  color: white;
}
.background {
  background-image: url("../Assets/background.png");
  background-size: cover;
  height: 100vh;
}
.background-h-230 {
  background-image: url("../Assets/background.png");
  background-size: cover;
  height: 230vh;
}
.background-h-150 {
  background-image: url("../Assets/background.png");
  background-size: cover;
  height: 150vh;
}

.container-chart {
  width: 400px;
  height: 236px;
}
.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text-white{
  color: white;
  margin-top: 48px;
  
}
.pv-25 {
  padding: 25px 0;
}
.mgb-10 {
  margin-bottom: 10px;
}
.input {
  border-radius: 12px;
  background-color: #161c22;
  color: #777777;
  font-weight: 400;
  font-size: 14px;
  border: none;
  width: 350px;
  height: 54px;
  padding-left: 20px;
  margin-top: 10px;
}
.button {
  margin-top: 50px;
}
.nav {
  display: flex;
  justify-content: space-around;
  width: 85vw;
  height: 80px;
  align-items: center;
}
.container-layout {
  padding-top: 150px;
}
.nav-item-active {
  color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.nav-item-inactive {
  color: white;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.nav-title {
  font-size: 24px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #ffffff;
  padding-left: 10px;
}
.nav-button {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 8px;
  padding: 10px 30px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.nav-button:hover {
  background: #7692ff;
  color: #091540;
}
.nav-div {
  width: 400px;
  display: flex;
  justify-content: space-evenly;
}
.container-nav {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 85vw;
  z-index: 100;
  background: transparent;
  backdrop-filter: blur(87.5px);
  top: 40px;
  border-radius: 16px;
}
.container-nav-top {
  background: #0B0B22;
}
.center{
  display: flex;
  justify-content: center;
}

.button {
  background-color: transparent;
  border: 1px solid #7692ff;
  color: #7692ff;
  border-radius: 100px;
  padding: 15px 55px;
  margin-top: 37px;
  font-weight: 600;
  font-size: 16px;
}
.button:hover {
  background-color: #7692ff;
  border: 1px solid #7c91f8;
  color: #091540;
  cursor: pointer;
}
.simulation-semihead {
  color: #7692ff;
  font-size: 16px;
}
.simulation-glassbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 64px;
  width: 420px;
  height: 270px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 16px;
}
.formular {
  margin-top: 30px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.graph {
  width: 330px;
  height: 200px;
}
.simulation-container {
  margin-top: 75px;
  padding-left: 207px;
  padding-right: 207px;
}
.simulation-output-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 50px 0;
}
.simulation-input-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.8);
}
.simulation-input-title {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
}
.simulation-output-header {
  font-weight: 700;
  font-size: 36px;
  color: #ffffff;
  margin-top: 8px;
  margin-bottom: 40px;
}
.simulation-graph-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding-bottom: 15px;
}
